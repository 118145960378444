import { Box, CircularProgress, useMediaQuery, keyframes } from '@mui/material';

const fadein = keyframes`
	from {
		opacity : 0;
	}
	to {
		opacity : 1;
	}
`;

export default function SplashLoading( {
	loading,
	text,
}: {
	loading: boolean, text?: string
} ) {
	const dark = useMediaQuery( '(prefers-color-scheme: dark)' );
	if ( !loading ) return null;
	return (
		<Box
			sx={{
				animation      : `${fadein} 0.8s`,
				zIndex         : 2000,
				position       : 'fixed',
				top            : 0,
				left           : 0,
				width          : '100vw',
				height         : '100vh',
				display        : 'flex',
				justifyContent : 'center',
				alignItems     : 'center',
				backgroundColor: dark ? '#131517' : '#ffffff',
			}}>
			<CircularProgress
				size={25}
				thickness={2.8}
				sx={{ color: dark ? '#616161' : '#9e9e9e' }}
			/>
		</Box>
	);
}
